import clsx from "clsx"
import React, { memo } from "react"
import type { FC } from "react"

type Props = {
  currency: string
  name: string
  isActive?: boolean
  onClick: () => void
  className?: string
}

const FiatItemComponent: FC<Props> = ({ currency, name, onClick, isActive, className }) => {
  return (
    <div className={clsx("item fiat-item", { active: isActive }, className)} onClick={onClick}>
      <span className="currency">{currency}</span>
      <span className="name">{name}</span>
    </div>
  )
}

export const FiatItem = memo(FiatItemComponent)
