import { Msg } from "@/Components/Msg"
import { useMemo, useState } from "react"
import "./style.sass"
const FAQ_PRICES_TABLE_ITEMS: { title: string; value: string }[] = [
  { title: "price1", value: "price14" },
  { title: "price2", value: "price15" },
  { title: "price3", value: "€20" },
  { title: "price4", value: "€15" },
  { title: "price5", value: "€0,6" },
  { title: "price6", value: "€0,65" },
  { title: "price7", value: "€3,00 + 1,5%" },
  { title: "price8", value: "€3,00 + 3%" },
  { title: "price9", value: "€2" },
  { title: "price10", value: "€2,5" },
  { title: "price11", value: "€0,6" },
  { title: "price12", value: "3%" },
  { title: "price13", value: "€40,00" },
]
export const Prices = () => {
  const [activePage, setActivePage] = useState("virtual")
  const tableItem = useMemo(() => {
    const tableItems = [...FAQ_PRICES_TABLE_ITEMS]
    //
    if (activePage === "virtual") return FAQ_PRICES_TABLE_ITEMS
    else {
      tableItems[3].value = "€0"
      tableItems[2] = { title: "price16", value: "€40" }
      tableItems.splice(3, 0, { title: "price17", value: "€10" })
      return tableItems
    }
  }, [activePage])
  return (
    <div className="faq-prices">
      <div className="faq-prices__tab">
        <span
          className={`faq-prices__tab-item${activePage === "virtual" ? " active" : ""}`}
          onClick={() => setActivePage("virtual")}
        >
          <strong>
            <Msg id="virtual" />
          </strong>
        </span>
        <span
          className={`faq-prices__tab-item${activePage === "physical" ? " active" : ""}`}
          onClick={() => setActivePage("physical")}
        >
          <strong>
            <Msg id="physical" />
          </strong>
        </span>
      </div>
      <div className="faq-prices__table">
        <div className="faq-prices__table-item">
          <div />
          <div>EUR</div>
        </div>
        {tableItem.map((item) => (
          <div className="faq-prices__table-item">
            <div>
              <Msg id={item.title} />{" "}
              {item.title === "price2" ? (
                <span style={{ color: "red" }}>(Maximum 500 EUR per month)</span>
              ) : (
                ""
              )}
            </div>
            <div>
              <Msg id={item.value} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
