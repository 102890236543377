import { Button } from "@/Components/Control/Button"
import { ICustomSelectProps } from "@/Components/Form/CustomSelect"
import { useOutsideClick } from "@/hooks"
import { IOption } from "@/types"
import classNames from "classnames"
import {
  CSSProperties,
  InputHTMLAttributes,
  KeyboardEventHandler,
  memo,
  ReactNode,
  useCallback,
  useState,
} from "react"
import { Triangle } from "./Triangle"
import clsx from "clsx"

const AVAILABLE_KEYS = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "6",
  "7",
  "8",
  "9",
  "0",
  "Backspace",
  "Delete",
]

const onKeyDownNumber: KeyboardEventHandler<HTMLDivElement> = (event) => {
  // @ts-expect-error
  const value: string = event.target.value

  const hasDot = value?.includes(".")

  if (AVAILABLE_KEYS.includes(event.key) || (event.key === "." && !hasDot && value.length > 0)) {
    return
  }

  event.preventDefault()
}

type Props = ICustomSelectProps & {
  inputValue: string | number
  buttonTitle: ReactNode
  onChangeInput: InputHTMLAttributes<HTMLInputElement>["onChange"]
  options: IOption<string, any>[]
  renderOption: (option: any, props: { onClick: () => void; onClose: () => void }) => ReactNode
  renderDropDownHeader?: (props: { onClose: () => void }) => ReactNode
  dropDownStyle?: CSSProperties
}

const ExchangeSelectComponent = ({
  placeholder,
  lightTheme = false,
  label,
  error,
  buttonTitle,
  renderDropDownHeader,
  renderOption,
  options,
  inputValue,
  onChangeInput,
  dropDownStyle,
  onChange,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const handleOpen = useCallback(() => {
    setIsOpen((x) => !x)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const dropDownRef = useOutsideClick(handleClose)

  const handleRenderOption = useCallback(
    (v: IOption<string, any>) =>
      renderOption(v, {
        onClose: handleClose,
        onClick: () => {
          onChange(v)
          handleClose()
        },
      }),
    [onChange, renderOption, handleClose]
  )

  return (
    <div
      className={clsx("custom-select exchange-select exchange-select-new position-relative", {
        focused: isFocused,
      })}
    >
      {label != null && (
        <span className={classNames({ "error-label": error })} style={{ marginLeft: 0 }}>
          {label}
        </span>
      )}
      <div
        className={classNames("custom-select__normal py-2 pe-2", {
          "custom-select__error": error,
          "custom-select__light": lightTheme,
        })}
      >
        <input
          onKeyDown={onKeyDownNumber}
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={onChangeInput}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <Button
          onClick={handleOpen}
          data-value="sell"
          style={{
            height: "100%",
            justifyContent: "flex-start",
            minWidth: "164px",
            maxWidth: "164px",
          }}
          variant={!isOpen ? "primary" : "outlined-black"}
        >
          <div
            className="d-flex justify-content-between gap-1 flex-1 align-items-center overflow-hidden"
            style={{ padding: "10px" }}
          >
            {buttonTitle ?? <div />}
            <Triangle isOpen={isOpen} />
          </div>
        </Button>
      </div>

      {isOpen ? (
        <div ref={dropDownRef} className="select-dropdown-wrapper" onClick={handleClose}>
          <div
            ref={dropDownRef}
            style={dropDownStyle}
            className="select-dropdown"
            onClick={(e) => e.stopPropagation()}
          >
            {renderDropDownHeader?.({ onClose: handleClose })}
            {options.map(handleRenderOption)}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export const ExchangeSelect = memo(ExchangeSelectComponent)
