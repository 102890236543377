import { Msg } from "@/Components/Msg"
import { ReactNode } from "react"
import { UseControllerOptions, useFormContext } from "react-hook-form"
import "./style.css"

interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode
}
export const Checkbox = ({ label, name, ...props }: ICheckboxProps) => {
  const { register, errors } = useFormContext()
  let rules: UseControllerOptions["rules"] = {}
  if (props.required) rules = { ...rules, required: { message: "Checkbox required", value: true } }
  return (
    <div>
      <label className="custom__checkbox">
        <input
          type="checkbox"
          id={`${name}_${props.value}`}
          className={`check__input ${props.className ?? ""}`}
          {...props}
          name={name}
          ref={register(rules)}
        />
        <span className="check__box" />
        {label}
      </label>
      {errors[name] != null && (
        <span className="check__helper">
          <Msg id="required_field" />
        </span>
      )}
    </div>
  )
}
