import { memo } from "react"
import type { FC } from "react"
import { ExchangeSelect } from "../ExchangeSelect"
import { IOption } from "@/types"
import { FiatItem } from "../FiatItem"
import { CryptoItem } from "../CryptoItem"
import { Button } from "@/Components/Control/Button"
import { CurrencyDto, CurrencyType } from "."
import { SelectorButton } from "../SelectorButton"
import { useIntl } from "react-intl"

type Props = {
  isMobile: boolean
  isLight?: boolean
  cryptoOptions: CurrencyType[]
  cryptoCurrency: CurrencyDto | null | undefined
  cryptoAmount: string
  onCryptoAmountChange: (amount: string) => void
  onCryptoCurrencyChange: (option: CurrencyDto) => void
  fiatOptions: CurrencyType[]
  fiatCurrency: CurrencyDto | null | undefined
  fiatAmount: string
  onFiatAmountChange: (amount: string) => void
  onFiatCurrencyChange: (option: CurrencyDto) => void
  onBuyClick: () => void
}

const BuyBlockComponent: FC<Props> = ({
  cryptoOptions,
  isMobile,
  isLight,
  cryptoCurrency,
  cryptoAmount,
  onCryptoAmountChange,
  onCryptoCurrencyChange,
  fiatOptions,
  fiatCurrency,
  fiatAmount,
  onBuyClick,
  onFiatAmountChange,
  onFiatCurrencyChange,
}) => {
  const { $t: t } = useIntl()

  return (
    <div className="d-flex gap-3">
      <div className="block-actions d-flex gap-2 align-items-center">
        <ExchangeSelect
          label={!isMobile ? t({ id: "you_spend" }) : undefined}
          lightTheme={isLight}
          buttonTitle={<SelectorButton code={fiatCurrency?.code} name={fiatCurrency?.name} />}
          inputValue={fiatAmount}
          onChangeInput={(x) => onFiatAmountChange(x.target.value)}
          onChange={(x) => onFiatCurrencyChange(x.payload)}
          options={fiatOptions}
          renderOption={(option: IOption<string, { name: string; rate: number }>, optionProps) => (
            <FiatItem
              {...optionProps}
              isActive={option.value === fiatCurrency?.code}
              key={option.value}
              name={option.payload.name}
              currency={option.label}
            />
          )}
        />
        {!isMobile ? <span style={{ marginTop: "26px" }}>=</span> : null}
        <ExchangeSelect
          label={!isMobile ? t({ id: "you_receive" }) : undefined}
          buttonTitle={<SelectorButton code={cryptoCurrency?.code} name={cryptoCurrency?.name} />}
          inputValue={cryptoAmount}
          onChangeInput={(x) => onCryptoAmountChange(x.target.value)}
          onChange={(x) => onCryptoCurrencyChange(x.payload)}
          options={cryptoOptions}
          renderOption={(option: IOption<string, { name: string; rate: number }>, optionProps) => (
            <CryptoItem
              {...optionProps}
              key={option.value}
              isActive={option.value === cryptoCurrency?.code}
              name={option.payload.name}
              currency={option.value}
            />
          )}
          lightTheme={isLight}
        />
      </div>

      {!isMobile ? (
        <Button className="action-button" onClick={onBuyClick}>
          {t({ id: "buy_button_title" })} {cryptoCurrency?.code ?? ""}
        </Button>
      ) : null}
    </div>
  )
}

export const BuyBlock = memo(BuyBlockComponent)
