import QueryString from "qs"
import { useCallback, useMemo } from "react"
import { useLocation } from "react-router-dom"

type Props<T> = {
  path?: string
  hash?: string
  defaultValues?: Partial<T>
}

export const useSearch = <T extends Record<string, string>>({
  path,
  hash,
  defaultValues,
}: Props<T> = {}): {
  query: T
  pathWithQuery: string
  getPath: (path?: string, hash?: string) => string
  pathname: string
} => {
  const { search, pathname } = useLocation()

  const query = useMemo(() => {
    const params = QueryString.parse(search?.replace(/\?/, "")) as T

    return {
      ...defaultValues,
      ...params,
    }
  }, [search, defaultValues])

  const pathWithQuery = useMemo(() => {
    if (path != null) {
      return [path, search, hash != null ? `#${hash}` : undefined].filter(Boolean).join("")
    }

    return ""
  }, [search, path, hash])

  const getPath = useCallback(
    (path?: string, hash?: string) => {
      if (path != null) {
        return [path, search, hash != null ? `#${hash}` : undefined].filter(Boolean).join("")
      }

      return ""
    },
    [search]
  )

  return { query, pathWithQuery, getPath, pathname }
}
