import { Image } from "@/Components/common/Image"
import { Button } from "@/Components/Control/Button"
import { FormWrapper } from "@/Components/Form"
import { Input } from "@/Components/Form/Input"
import { Msg } from "@/Components/Msg"
import { Text } from "@/Components/Text"
import { Container } from "@/Layout/Container"
import { Paper } from "@/Layout/Paper"
import { useRecoveryForm, VALID_EMAIL } from "@usher/pe-client-front-end-lib"
import { Alert } from "react-bootstrap"
import { useIntl } from "react-intl"
import "./style.sass"

export const Recovery = () => {
  const intl = useIntl()
  const { formMethods, onSubmit, email, loading } = useRecoveryForm()
  return (
    <div className="password__recovery">
      <Container>
        <div className="text-center">
          <Text variant="paperTitle">
            <Msg id="password_recovery" />
          </Text>
        </div>

        {email && (
          <Alert variant="success">
            <p>
              <Msg id="password_recovery_alert1" vars={{ email }} />
              <br />
              <Msg id="password_recovery_alert2" />
            </p>
          </Alert>
        )}
        <Paper>
          <FormWrapper methods={formMethods} onSubmit={onSubmit}>
            <div className="recovery__control">
              <Input
                label={intl.formatMessage({ id: "your_email" })}
                isEmail
                type="email"
                name="email"
                required
                rules={VALID_EMAIL}
              />
              <Button loading={loading} type="submit">
                <Msg id="restore" />
              </Button>
            </div>
            <div className="recovery__contacts">
              <span className="password__title">{intl.formatMessage({ id: "footer4" })}</span>
              <ul className="password-list">
                <li className="password-list__item">
                  <Image src="footer-phone1.svg" alt="+44 2080 971 790" />
                  <a className="password-list__link" href="tel:+44 2080 971 790">
                    +44 2080 971 790
                  </a>
                </li>
                <li className="password-list__item">
                  <Image src="footer-phone2.svg" alt="+370 700 310 96" />
                  <a className="password-list__link" href="tel:+370 700 310 96">
                    +370 700 310 96
                  </a>
                </li>
              </ul>
              <div className="password__email">
                <Msg id="email_add" tag="span" />
                <a href="mailto:support@wizarbit.com">support@wizarbit.com</a>
              </div>
            </div>
          </FormWrapper>
        </Paper>
      </Container>
    </div>
  )
}
