import "@/Components/Box/index.sass"
import "@/Components/PaymentCard/index.sass"
import { Layout } from "@/Layout"
import { Container } from "@/Layout/Container"
import { Paper } from "@/Layout/Paper"
import { cfgExchange, useExchangePage } from "@usher/pe-client-front-end-lib"
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom"
import { PaymentDetails } from "./components/PaymentDetails"
import { Stepper } from "./components/Stepper"
import { DocumentVerification } from "./DocumentVerification"
import { Payment } from "./ExchangePayment"
import { PathGuard } from "./PathGuard"
import { PersonalInfo } from "./PersonalInfo"
import { StatusCheck } from "./StatusCheck"
import "./style.sass"
import { TransactionDetails } from "./TransactionDetails"

export const Exchange = () => {
  const path = useRouteMatch().path

  const { STEP1, STEP2, STEP3, STEP4, STEP5 } = cfgExchange.STEPS

  const { pathname } = useLocation()
  const { isInitialized, cryptoAmount, cryptoCurrency, fiatAmount, fiatCurrency } = useExchangePage(
    { pathname },
  )
  const isLastStep = pathname === `${path}/${STEP5.route}`
  return (
    <Layout mb={70}>
      <Container className="transaction-wrapper">
        <Stepper />
        <Paper padding="0">
          <div
            className="transaction-form"
            style={{ alignItems: isLastStep ? "center" : "initial" }}
          >
            <div className="left_section">
              {isInitialized && (
                <Switch>
                  <Route exact path={path} component={PathGuard} />
                  <Route path={`${path}/${STEP1.route}`} component={TransactionDetails} />
                  <Route path={`${path}/${STEP2.route}`} component={PersonalInfo} />
                  <Route path={`${path}/${STEP3.route}`} component={DocumentVerification} />
                  <Route path={`${path}/${STEP4.route}`} component={Payment} />
                  <Route path={`${path}/${STEP5.route}`} component={StatusCheck} />
                </Switch>
              )}
            </div>
            <PaymentDetails
              isLastStep={isLastStep}
              {...{
                cryptoAmount,
                cryptoCurrency,
                fiatAmount,
                fiatCurrency,
              }}
            />
          </div>
        </Paper>
      </Container>
    </Layout>
  )
}
