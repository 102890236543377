import { memo } from "react"
import type { FC } from "react"
import { ExchangeSelect } from "../ExchangeSelect"
import { IOption } from "@/types"
import { CryptoItem } from "../CryptoItem"
import { Button } from "@/Components/Control/Button"
import { CardsItem } from "../CardsItem"
import { CardType, CurrencyDto, CurrencyType } from "."
import { SelectorButton } from "../SelectorButton"
import { useIntl } from "react-intl"
import { noop } from "jquery"

type Props = {
  isMobile: boolean
  isLight?: boolean
  cardType: CardType
  onCardTypeChange: (cardType: CardType) => void
  onFiatAmountChange: (amount: string) => void
  cryptoOptions: CurrencyType[]
  cryptoCurrency: CurrencyDto | null | undefined
  cryptoAmount: string
  fiatAmount: string
  onCryptoAmountChange: (amount: string) => void
  onCryptoCurrencyChange: (option: CurrencyDto) => void
  fiatOptions: CurrencyType[]
  fiatCurrency: CurrencyDto | null | undefined
  onFiatCurrencyChange: (option: CurrencyDto) => void
  onSellClick: () => void
}

const SellBlockComponent: FC<Props> = ({
  isMobile,
  isLight,
  cardType,
  onCardTypeChange,
  cryptoOptions,
  cryptoCurrency,
  cryptoAmount,
  fiatAmount,
  onCryptoAmountChange,
  onFiatAmountChange,
  onCryptoCurrencyChange,
  fiatOptions,
  fiatCurrency,
  onFiatCurrencyChange,
  onSellClick,
}) => {
  const { $t: t } = useIntl()

  return (
    <div className="d-flex gap-3">
      <div className="block-actions d-flex gap-2 align-items-center">
        <ExchangeSelect
          label={!isMobile ? t({ id: "you_receive" }) : undefined}
          buttonTitle={<SelectorButton code={cryptoCurrency?.code} name={cryptoCurrency?.name} />}
          inputValue={cryptoAmount}
          onChangeInput={(x) => onCryptoAmountChange(x.target.value)}
          onChange={(x) => onCryptoCurrencyChange(x.payload)}
          options={cryptoOptions}
          renderOption={(option: IOption<string, { name: string; rate: number }>, optionProps) => (
            <CryptoItem
              {...optionProps}
              isActive={option.value === cryptoCurrency?.code}
              key={option.value}
              name={option.payload.name}
              currency={option.value}
            />
          )}
          lightTheme={isLight}
        />
        {!isMobile ? <span style={{ marginTop: "26px" }}>=</span> : null}
        <ExchangeSelect
          label={!isMobile ? t({ id: "you_spend" }) : undefined}
          buttonTitle={
            <SelectorButton
              code={fiatCurrency?.code}
              name={t({ id: `card_type_title_${cardType}` })}
            />
          }
          inputValue={fiatAmount}
          onChangeInput={(x) => onFiatAmountChange(x.target.value)}
          onChange={noop}
          options={[]}
          dropDownStyle={{ border: "none" }}
          renderDropDownHeader={({ onClose }) => (
            <CardsItem
              cardType={cardType}
              onCardTypeChange={(type) => {
                onClose()
                onCardTypeChange(type)
              }}
              currencies={fiatOptions}
              currency={fiatCurrency}
              onFiatCurrencyChange={onFiatCurrencyChange}
            />
          )}
          renderOption={() => null}
          lightTheme={isLight}
        />
      </div>

      {!isMobile ? (
        <Button className="action-button" onClick={onSellClick}>
          {t({ id: "sell_button_title" })} {cryptoCurrency?.code ?? ""}
        </Button>
      ) : null}
    </div>
  )
}

export const SellBlock = memo(SellBlockComponent)
