import { Layout } from "@/Layout"
import { HeroScreen } from "./HeroScreen"
import { HomeAssets } from "./HomeAssets"
import "./index.sass"
import { OurCards } from "./OurCards"
import { WhyUs } from "./WhyUs"
import { HowToBuy } from "./HowToBuy"
import { Payments } from "./Payments"
import { Help } from "./Help"
import { InstantPayouts } from "./InstantPayouts"
import { HowToSell } from "./HowToSell"

export const Home = () => {
  return (
    <Layout withHeader={true}>
      <div className="Home">
        <HeroScreen />
        <OurCards />
        <InstantPayouts />
        <HomeAssets />
        <WhyUs />
        <HowToBuy />
        <HowToSell />
        <Payments />
        <Help />
        {/* <HomeAssets />
        <HomeContact />
        <HomeSteps />
        <HomeFeatures />
        <HomeExchange /> */}
      </div>
    </Layout>
  )
}
