import { Button } from "@/Components/Control/Button"
import { Modal } from "@/Components/Modal"
import { Msg } from "@/Components/Msg"
import { FunctionComponent } from "react"

interface props {
  isShow: boolean
  setShowSkip: (state: boolean) => void
  onConfirm: () => void
}
export const SkipModal: FunctionComponent<props> = ({ isShow, setShowSkip, onConfirm }) => {
  return (
    <>
      <span className="document_upload_skip" onClick={() => setShowSkip(true)}>
        <Msg id="dv7" />
      </span>
      <Modal size={1} isOpen={isShow} handleClose={() => setShowSkip(false)}>
        <div className="Modal__Title">
          <Msg id="dv9" />
        </div>
        <div className="Modal__Description">
          <Msg id="dv8" />
        </div>
        <div className="Modal__Button">
          <Button style={{ minWidth: 272 }} onClick={onConfirm}>
            <Msg id="dv10" />
          </Button>
          <Button variant="outlined" onClick={() => setShowSkip(false)}>
            <Msg id="cancel" />
          </Button>
        </div>
      </Modal>
    </>
  )
}
