import { RefObject, useEffect, useRef } from "react"

export const useOutsideClick = <T = HTMLDivElement>(onOutsideClick: () => void): RefObject<T> => {
  const ref = useRef<T>()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // @ts-expect-error
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref, onOutsideClick])

  return ref
}
