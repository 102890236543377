import { Image } from "@/Components/common/Image"
import "./styles.sass"

import { memo } from "react"
import type { FC } from "react"
import { Container } from "@/Layout/Container"
import { Msg } from "@/Components/Msg"

const HowToSellComponent: FC = () => {
  return (
    <div className="how-to-sell">
      <Container>
        <div className="image-content-container">
          <div className="image-content" />
        </div>
        <div className="text-content">
          <h2 className="h2 title">
            <Msg id="how-to-sell-title" />
          </h2>

          <div className="step">
            <div className="number">
              <Image src="by-1.png" alt="" />
            </div>

            <div className="info">
              <span className="text-title">
                <Msg id="how-to-sell-one-title" />
              </span>
              <span className="text">
                <Msg id="how-to-sell-one-text" />
              </span>
            </div>
          </div>

          <div className="step">
            <div className="number">
              <Image src="by-2.png" alt="" />
            </div>

            <div className="info">
              <span className="text-title">
                <Msg id="how-to-sell-two-title" />
              </span>
              <span className="text">
                <Msg id="how-to-sell-two-text" />
              </span>
            </div>
          </div>
          <div className="step">
            <div className="number">
              <Image src="by-3.png" alt="" />
            </div>
            <div className="info">
              <span className="text-title">
                <Msg id="how-to-sell-three-title" />
              </span>
              <span className="text">
                <Msg id="how-to-sell-three-text" />
              </span>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export const HowToSell = memo(HowToSellComponent)
