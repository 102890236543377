import { Image } from "@/Components/common/Image"
import "./styles.sass"

import { Msg } from "@/Components/Msg"
import { Container } from "@/Layout/Container"
import { memo } from "react"
import type { FC } from "react"

type Props = {
  //
}

const InstantPayoutsComponent: FC<Props> = () => {
  return (
    <section className="home_section instant-payouts">
      <Container>
        <h2 className="h2 title">
          <Msg id="instant-payouts-title" />
        </h2>
        <div className="info-block">
          <span className="info-title">
            <Msg id="instant-payouts-info-title" />
          </span>
          <span className="info-description">
            <Msg id="instant-payouts-info-description" />
          </span>
        </div>

        <div className="d-flex">
          <div className="notify-block">
            <Msg id="instant-payouts-notify" />
          </div>
        </div>

        <div className="step">
          <div className="number">
            <Image src="by-1.png" alt="" />
          </div>
          <span className="text">
            <Msg id="instant-payouts-one" />
          </span>
        </div>
        <div className="step">
          <div className="number">
            <Image src="by-2.png" alt="" />
          </div>

          <span className="text">
            <Msg id="instant-payouts-two" />
          </span>
        </div>
        <div className="step">
          <div className="number">
            <Image src="by-3.png" alt="" />
          </div>
          <span className="text">
            <Msg id="instant-payouts-three" />
          </span>
        </div>
      </Container>
    </section>
  )
}

export const InstantPayouts = memo(InstantPayoutsComponent)
