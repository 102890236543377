import "./index.sass"
import { Container } from "@/Layout/Container"
import { HeroBlock } from "./HeroBlock"
import { Tabs } from "./Tabs"
import { useSearch } from "@/hooks/useSearch"
import { useIntl } from "react-intl"

export const HeroScreen = () => {
  const { $t: t } = useIntl()
  const { query } = useSearch<{ type: "buy" | "sell" }>({ defaultValues: { type: "buy" } })

  const isBuyType = query.type === "buy"

  return (
    <section className="home_section">
      <Container className="hero-container d-flex flex-column gap-3">
        <Tabs />

        <span className="align-self-center text-center mb-2 hero-title">
          {t({ id: isBuyType ? "buy_title" : "sell_title" })}
        </span>

        <HeroBlock isBuyType={isBuyType} />
      </Container>
    </section>
  )
}
