import { memo } from "react"
import type { FC } from "react"

type Props = {
  code: string
  name: string
}

const SelectorButtonComponent: FC<Props> = ({ code, name }) => {
  return (
    <div className="selector-button">
      <span className="code">{code ?? ""}</span>
      <span className="name">{name ?? ""}</span>
    </div>
  )
}

export const SelectorButton = memo(SelectorButtonComponent)
