import { Button } from "@/Components/Control/Button"
import { CtrlReCaptcha } from "@/Components/ctrl/ReCaptcha"
import { FormWrapper } from "@/Components/Form"
import { Input } from "@/Components/Form/Input"
import { Msg } from "@/Components/Msg"
import { Layout } from "@/Layout"
import { Stack } from "@/Layout/Stack"
import { CountrySelect } from "@/NewComponents/CountrySelect"
import { scrollTo } from "@/utils"
import {
  useAffiliateForm,
  VALID_EMAIL,
  VALID_NAME,
  VALID_PHONE,
} from "@usher/pe-client-front-end-lib"
import { useIntl } from "react-intl"
import "./style.css"

export const ReferalProgram = () => {
  const intl = useIntl()
  const { formMethods, onSubmit, captchaRef, onCaptchaChange } = useAffiliateForm()
  return (
    <Layout darkTheme smallHeader>
      <main className="main referal-main">
        <section className="refer">
          <div className="container">
            <div className="refer__wrap">
              <h1 className="h2 refer__title">
                <Msg id="ref1" />
              </h1>
              <div className="refer__slogan">
                <Msg id="ref2" />
              </div>
              <Button variant="primary" onClick={() => scrollTo("join")}>
                <Msg id="ref3" />
              </Button>
            </div>
          </div>
        </section>

        <section className="sing-up sing-up__referal" id="sing-up">
          <div id="join" className="container">
            <div className="sing-up__wrap">
              <h2 className="h2 sing-up-title">
                <Msg id="ref4" />
              </h2>
              <div className="sing-up-form">
                <h3 className="sing-up-form__title">
                  <Msg id="contact_details" />
                </h3>
                <FormWrapper methods={formMethods} onSubmit={onSubmit}>
                  <div className="sing-up__box-label">
                    <Input
                      dark
                      name="first_name"
                      label={intl.formatMessage({ id: "fname" })}
                      required
                      rules={VALID_NAME}
                    />
                    <Input
                      dark
                      name="last_name"
                      label={intl.formatMessage({ id: "lname" })}
                      required
                    />
                  </div>

                  <div className="sing-up__box-label">
                    <Input
                      dark
                      name="email"
                      isEmail
                      type="email"
                      rules={VALID_EMAIL}
                      required
                      label={intl.formatMessage({ id: "email_used" })}
                    />
                    <Input dark name="skype" label="Skype" />
                  </div>

                  <Stack mb={55}>
                    <CountrySelect
                      isDark
                      label={intl.formatMessage({ id: "country" })}
                      name="country_code"
                      required
                    />
                    <Input
                      dark
                      label={intl.formatMessage({ id: "phone" })}
                      name="phone"
                      rules={{ ...VALID_PHONE, minLength: undefined, maxLength: undefined }}
                      required
                    />
                  </Stack>
                  <h3 className="sing-up-form__title">
                    <Msg id="about_traffic" />
                  </h3>
                  <div className="sing-up__box-label sing-up__box-margin">
                    <Input
                      dark
                      name="website"
                      label={intl.formatMessage({ id: "website_url" })}
                      required
                    />
                    <Input
                      dark
                      name="visitors_count"
                      label={intl.formatMessage({ id: "visitor_count" })}
                      required
                    />
                  </div>

                  <div className="captcha">
                    <CtrlReCaptcha refObj={captchaRef as never} onChange={onCaptchaChange} />
                  </div>
                  <Button type="submit">
                    <Msg id="ref3" />
                  </Button>
                </FormWrapper>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}
