import clsx from "clsx"
import React, { memo } from "react"
import type { FC } from "react"

type Props = {
  currency: string
  name: string
  onClick: () => void
  isActive?: boolean
}

const CryptoItemComponent: FC<Props> = ({ currency, name, onClick, isActive }) => {
  return (
    <div
      className={clsx("item crypto-item", { active: isActive })}
      style={{ height: "68px" }}
      onClick={onClick}
    >
      <div className="d-flex gap-2 align-items-center overflow-hidden">
        <div className="icon d-flex align-items-center justify-content-center">
          <img
            style={{ width: "26px", height: "26px" }}
            src={`/img/icons_new/${currency.toLowerCase().split("-")[0]}_coin.svg`}
            alt=""
          />
        </div>
        <span className="name">{name}</span>
      </div>
      <span className="currency">{currency}</span>
    </div>
  )
}

export const CryptoItem = memo(CryptoItemComponent)
