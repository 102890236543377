import { Image } from "@/Components/common/Image"
import { Button } from "@/Components/Control/Button"
import { Msg } from "@/Components/Msg"
import { scrollTo } from "@/utils"

export const Purch = () => {
  return (
    <section className="purch">
      <div className="container">
        <div className="purch__wrap">
          <h2 className="secure_title h2" style={{ position: "relative", zIndex: 1 }}>
            <Msg id="purchase1" />
          </h2>
          <div className="purch__position" style={{ zIndex: 0, marginRight: -50 }}>
            <Image src="cards-purpl.png" />
            <Image src="cards-black.png" />
          </div>
          <div className="purch__description">
            <Msg id="purchase2" />
          </div>
          <Button minWidth={219} variant="primary" onClick={() => scrollTo("sing-up")}>
            <Msg id="order_card" />
          </Button>
        </div>
      </div>
    </section>
  )
}
