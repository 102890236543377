import { Button } from "@/Components/Control/Button"
import { FormWrapper } from "@/Components/Form"
import { Checkbox } from "@/Components/Form/Checkbox"
import { Input } from "@/Components/Form/Input"
import { Msg } from "@/Components/Msg"
import { Stack } from "@/Layout/Stack"
import { CountrySelect } from "@/NewComponents/CountrySelect"
import { useExchangeStep2, VALID_PHONE } from "@usher/pe-client-front-end-lib"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import {
  AgreeAgeCheck,
  AgreeRefundCheck,
  AgreeRiskCheck,
  AgreeTermCheck,
} from "../Auth/Register/Checkboxes"

export const PersonalInfo = () => {
  const history = useHistory()
  const intl = useIntl()
  const { loading, formMethods, onSubmit } = useExchangeStep2({ history: useHistory() })
  return (
    <div className="personal_info">
      <FormWrapper methods={formMethods} noValidate onSubmit={onSubmit}>
        <h4 className="transaction__title">
          <Msg id="pi1" />
        </h4>
        <Stack gap={20}>
          <Input label={intl.formatMessage({ id: "fname" })} required name="first_name" />
          <Input label={intl.formatMessage({ id: "lname" })} required name="last_name" />
        </Stack>
        <Stack gap={20}>
          <Input
            label={intl.formatMessage({ id: "phone" })}
            required
            name="phone"
            rules={{ ...VALID_PHONE, minLength: undefined, maxLength: undefined }}
          />
          <CountrySelect
            name="nationality"
            required
            fullWidth
            label={intl.formatMessage({ id: "national" })}
          />
        </Stack>
        <div className="select_sex">
          <Checkbox name="sex" value="1" required label={<Msg id="male" />} type="radio" />
          <Checkbox name="sex" value="0" required label={<Msg id="female" />} type="radio" />
        </div>
        <div className="personal__box-title">
          <h4 className="transaction__title personal__title">
            <Msg id="pi2" />
          </h4>
          <p className="transaction__subtitle">
            <Msg id="pi3" />
          </p>
        </div>
        <Stack gap={20}>
          <CountrySelect
            name="country_code"
            required
            fullWidth
            label={intl.formatMessage({ id: "country" })}
          />
          <Input label={intl.formatMessage({ id: "state" })} required name="state" />
        </Stack>
        <Stack gap={20}>
          <Input label={intl.formatMessage({ id: "city" })} required name="city" />
          <Input label={intl.formatMessage({ id: "zip" })} required name="zip" />
        </Stack>
        <Stack gap={20}>
          <Input label={intl.formatMessage({ id: "saddress" })} required name="address" />
          <Input label={intl.formatMessage({ id: "apt" })} required name="address2" />
        </Stack>

        <div className="policy">
          <AgreeTermCheck />
          <AgreeRiskCheck />
          <AgreeRefundCheck />
          <AgreeAgeCheck />
        </div>

        <div className="exchange__actions">
          <Button loading={loading} variant="primary" type="submit">
            <Msg id="exchange" />
          </Button>
          <Button variant="outlined" onClick={() => history.push("/")}>
            <Msg id="cancel" />
          </Button>
        </div>
      </FormWrapper>
    </div>
  )
}
