import React, { memo } from "react"
import type { FC } from "react"

type Props = {
  isOpen: boolean
}

const TriangleComponent: FC<Props> = ({ isOpen }) => {
  return (
    <div
      style={{
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: "0 4px 8px 4px",
        borderColor: "transparent transparent #ffffff transparent",
        transform: `rotate(${isOpen ? 0 : 180}deg)`,
      }}
    />
  )
}

export const Triangle = memo(TriangleComponent)
