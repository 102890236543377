import { Msg } from "@/Components/Msg"
import { CompanyPhoneNumber, COMPANY_EMAIL_ANCHOR } from "@/constants/CommonConstants"
import { ReactNode } from "react"
import { Prices } from "./components/Prices"

export interface IListItem {
  id: number
  date: string
  title: string
  listTitle?: string
  content: ReactNode
  hidden?: boolean
  redirect?: number
}
export interface IFaqList {
  id: number
  title: string
  prefix: string
  countOnPaper?: number
  list: IListItem[]
}
export const faq_list = (): IFaqList[] => [
  {
    id: 1,
    title: "faq_title1",
    prefix: "account-verification",
    list: [
      {
        id: 1,
        date: "2024-09-13 at 11:47 PM",
        title: "faq_title2",
        content: (
          <>
            <p>
              <Msg id="faq1" />
            </p>
            <ol>
              <li>
                <Msg id="faq2" />
              </li>
              <li>
                <Msg id="faq3" />
              </li>
              <li>
                <Msg id="faq4" />
              </li>
            </ol>
            <p className="mb-0">
              <Msg
                id="faq5"
                vars={{ a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a> }}
              />
            </p>
          </>
        ),
      },
      {
        id: 2,
        date: "2024-11-26 at 11:47 PM",
        title: "faq_title3",
        content: (
          <>
            <p>
              <Msg id="faq7" />
            </p>
            <p>
              <Msg id="faq8" />
            </p>
            <p className="mb-0">
              <Msg id="faq9" />
            </p>
          </>
        ),
      },
      {
        id: 3,
        date: "2024-09-06 at 10:19 PM",
        title: "faq_title4",
        content: (
          <>
            <p>
              <Msg id="faq10" />
            </p>
            <ul className="lined_list">
              <li>
                <Msg id="faq11" />
              </li>
              <li>
                <Msg id="faq12" />
              </li>
              <li>
                <Msg id="faq13" />
              </li>
              <li>
                <Msg id="faq14" />
              </li>
            </ul>
            <p>
              <Msg id="faq15" />
            </p>
            <p className="mb-0">
              <Msg
                id="faq16"
                vars={{ a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a> }}
              />
            </p>
          </>
        ),
      },
      {
        id: 4,
        date: "2024-11-26 at 11:47 PM",
        title: "faq_title5",
        content: (
          <p className="mb-0">
            <Msg id="faq17" />
          </p>
        ),
      },
      {
        id: 5,
        date: "2024-09-14 at 05:47 PM",
        title: "faq_title6",
        content: (
          <p className="mb-0">
            <Msg id="faq18" />
          </p>
        ),
      },
      {
        id: 6,
        date: "2024-11-26 at 11:47 PM",
        title: "faq_title7",
        content: (
          <>
            <ul className="lined_list">
              <li>
                <Msg id="faq20" />
              </li>
              <li>
                <Msg id="faq21" />
              </li>
              <li>
                <Msg id="faq22" />
              </li>
              <li>
                <Msg id="faq23" />
              </li>
              <li>
                <Msg id="faq24" />
              </li>
              <li>
                <Msg id="faq25" />
              </li>
              <li>
                <Msg id="faq26" />
              </li>
            </ul>
            <p className="mb-0">
              <Msg id="faq27" />
            </p>
          </>
        ),
      },
      {
        id: 7,
        date: "2024-11-26 at 11:47 PM",
        title: "faq_title8",
        content: (
          <p className="mb-0">
            <Msg id="faq29" />
          </p>
        ),
      },
      {
        id: 8,
        date: "2024-09-12 at 09:41 PM",
        title: "faq_title9",
        content: (
          <>
            <ol>
              <li>
                <Msg id="faq30" />
              </li>
              <li>
                <Msg id="faq31" />
              </li>
              <li>
                <Msg id="faq32" />
              </li>
              <li>
                <Msg id="faq33" />
              </li>
            </ol>
            <p>
              <strong>
                <Msg id="faq34" />
              </strong>
            </p>
            <ul className="lined_list">
              <li>
                <Msg id="faq35" />
              </li>
              <li>
                <Msg id="faq36" />
              </li>
              <li>
                <Msg id="faq37" />
              </li>
              <li>
                <Msg id="faq38" />
              </li>
              <li>
                <Msg id="faq40" />
              </li>
              <li>
                <Msg id="faq41" />
              </li>
              <li>
                <Msg id="faq42" />
              </li>
              <li>
                <Msg id="faq43" />
              </li>
              <li>
                <Msg id="faq44" />
              </li>
              <li>
                <Msg id="faq45" />
              </li>
            </ul>
            <p className="mb-0">
              <Msg id="faq46" />
            </p>
          </>
        ),
      },
      {
        id: 9,
        date: "2024-09-09 at 03:27 PM",
        title: "faq_title10",
        content: (
          <p className="mb-0">
            <Msg
              id="faq48"
              vars={{
                a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
              }}
            />{" "}
          </p>
        ),
      },
      {
        id: 10,
        date: "2024-11-26 at 11:47 PM",
        title: "faq_title11",
        content: (
          <p className="mb-0">
            <Msg id="faq49" />
          </p>
        ),
      },
    ],
  },
  {
    id: 2,
    title: "faq_title12",
    prefix: "my-account",
    list: [
      {
        id: 1,
        date: "2024-11-26 at 11:47 PM",
        title: "faq_title13",
        content: (
          <>
            <p>
              <Msg id="faq50" />
            </p>
            <ul className="mb-0 lined_list">
              <li>
                <Msg id="faq51" />
              </li>
              <li>
                <Msg id="faq53" />
              </li>
              <li>
                <Msg id="faq53" />
              </li>
              <li>
                <Msg id="faq54" />
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 2,
        date: "2024-09-13 at 05:23 PM",
        title: "faq_title14",
        content: (
          <p className="mb-0">
            <Msg id="faq55" vars={{ COMPANY_EMAIL: COMPANY_EMAIL_ANCHOR }} />
          </p>
        ),
      },
      {
        id: 3,
        date: "2024-09-12 at 02:48 PM",
        title: "faq_title15",
        content: (
          <p className="mb-0">
            <Msg id="faq56" vars={{ COMPANY_EMAIL: COMPANY_EMAIL_ANCHOR }} />
          </p>
        ),
      },
      {
        id: 4,
        date: "2024-08-15 at 07:47 PM",
        title: "faq_title16",
        content: (
          <p className="mb-0">
            <Msg id="faq57" vars={{ COMPANY_EMAIL: COMPANY_EMAIL_ANCHOR }} />
          </p>
        ),
      },
      {
        id: 5,
        date: "2024-09-14 at 08:07 PM",
        title: "faq_title17",
        content: (
          <>
            <p>
              <Msg id="faq58" />
            </p>
            <ul className="mb-0 lined_list">
              <li>
                <Msg id="faq59" />
              </li>
              <li>
                <Msg id="faq60" />
              </li>
              <li>
                <Msg id="faq61" />
              </li>
              <li>
                <Msg id="faq62" />
              </li>
            </ul>
          </>
        ),
      },
      {
        id: 6,
        date: "2024-09-15 at 03:34 PM",
        title: "faq63",
        content: (
          <p className="mb-0">
            <Msg id="faq64" vars={{ COMPANY_EMAIL: COMPANY_EMAIL_ANCHOR }} />
          </p>
        ),
      },
      {
        id: 7,
        date: "2024-09-14 at 07:39 PM",
        title: "faq65",
        content: (
          <p className="mb-0">
            <Msg id="faq66" vars={{ COMPANY_EMAIL: COMPANY_EMAIL_ANCHOR }} />
          </p>
        ),
      },
    ],
  },
  {
    id: 3,
    title: "security",
    prefix: "security",
    list: [
      {
        id: 1,
        date: "2024-11-26 at 11:47 PM",
        title: "faq67",
        content: (
          <>
            <p>
              <Msg id="faq68" />
            </p>
            <ul className="lined_list">
              <li>
                <Msg id="faq69" />
              </li>
              <li>
                <Msg id="faq70" />
              </li>
            </ul>
            <p>
              <Msg id="faq71" />
            </p>
            <p className="mb-0">
              <Msg id="faq72" />
            </p>
          </>
        ),
      },
      // {
      //   id: 2,
      //   date: "Tue, 26 Nov, 2019 at 11:47 PM",
      //   title: "faq73",
      //   content: (
      //     <>
      //       <p>
      //         <Msg id="faq74" />
      //       </p>
      //       <ul className="lined_list">
      //         <li>
      //           <Msg id="faq75" />
      //         </li>
      //         <li>
      //           <Msg id="faq76" />
      //         </li>
      //         <li>
      //           <Msg id="faq77" />
      //         </li>
      //         <li>
      //           <Msg id="faq78" />
      //         </li>
      //       </ul>
      //       <p className="mb-0">
      //         <Msg id="faq79" />
      //       </p>
      //     </>
      //   ),
      // },
      // {
      //   id: 3,
      //   date: "Tue, 26 Nov, 2019 at 11:47 PM",
      //   title: "faq80",
      //   content: (
      //     <>
      //       <p>
      //         <Msg id="faq81" />
      //       </p>
      //       <ul className="lined_list">
      //         <li>
      //           <Msg id="login" />
      //         </li>
      //         <li>
      //           <Msg id="faq82" />
      //         </li>
      //         <li>
      //           <Msg id="faq83" />
      //         </li>
      //         <li>
      //           <Msg id="faq84" />
      //         </li>
      //       </ul>
      //       <p className="mb-0">
      //         <Msg id="faq85" />
      //       </p>
      //     </>
      //   ),
      // },
      {
        id: 4,
        date: "2024-09-12 at 11:47 PM",
        title: "faq86",
        content: (
          <p className="mb-0">
            <Msg id="faq87" />
          </p>
        ),
      },
      {
        id: 5,
        date: "2024-11-26 at 11:47 PM",
        title: "faq88",
        content: (
          <>
            <p>
              <Msg id="faq89" />
            </p>
            <p className="mb-0">
              <Msg id="faq90" />
            </p>
          </>
        ),
      },
    ],
  },
  {
    id: 4,
    title: "faq91",
    prefix: "receiving",
    list: [
      {
        id: 1,
        date: "2024-09-15 at 01:11 PM",
        title: "faq92",
        content: (
          <p className="mb-0">
            <Msg
              id="faq93"
              vars={{
                a: (chunk: string) => <a href="/legal/refund-policy">{chunk}</a>,
              }}
            />
          </p>
        ),
      },
      {
        id: 2,
        date: "2024-09-07 at 04:09 PM",
        title: "faq95",
        content: (
          <p className="mb-0">
            <Msg id="faq96" />
          </p>
        ),
      },
      {
        id: 3,
        date: "2024-09-05 at 10:09 AM",
        title: "faq97",
        content: (
          <p className="mb-0">
            <Msg id="faq98" />
          </p>
        ),
      },
      {
        id: 4,
        date: "2024-11-26 at 11:47 PM",
        title: "faq99",
        content: (
          <p className="mb-0">
            <Msg id="faq100" />
          </p>
        ),
      },
      {
        id: 5,
        date: "2024-09-08 at 08:14 AM",
        title: "faq101",
        content: (
          <p className="mb-0">
            <Msg id="faq102" />
          </p>
        ),
      },
      {
        id: 6,
        date: "2024-11-26 at 11:47 PM",
        title: "faq103",
        content: (
          <>
            <p>
              <Msg id="faq104" />
            </p>
            {/* <p className="mb-0">
              <Msg id="faq105" />
            </p> */}
          </>
        ),
      },
    ],
  },
  {
    id: 5,
    title: "faq106",
    prefix: "verification-levels",
    list: [
      {
        id: 1,
        date: "2024-09-05 at 09:43 PM",
        title: "faq107",
        content: (
          <p className="mb-0">
            <Msg
              id="faq108"
              vars={{
                a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
              }}
            />
          </p>
        ),
      },
      {
        id: 2,
        date: "2024-09-05 at 09:47 PM",
        title: "faq110",
        content: (
          <p className="mb-0">
            <Msg
              id="faq108"
              vars={{
                a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
              }}
            />
          </p>
        ),
      },
      {
        id: 3,
        date: "2024-09-05 at 09:47 PM",
        title: "faq112",
        content: (
          <p className="mb-0">
            <Msg
              id="faq108"
              vars={{
                a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
              }}
            />
          </p>
        ),
      },
      {
        id: 4,
        date: "2024-09-05 at 09:50 PM",
        title: "faq113",
        content: (
          <p className="mb-0">
            <Msg
              id="faq114"
              vars={{
                a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
              }}
            />
          </p>
        ),
      },
    ],
  },
  {
    id: 6,
    title: "card_faq_title",
    prefix: "faq_cards",
    list: [
      {
        id: 10,
        date: "2024-11-06 at 01:21 PM",
        title: "card_faq1",
        redirect: 1,
        content: (
          <p className="mb-0">
            <Msg id="exploring" />
            &nbsp;&nbsp;&nbsp;{" "}
            <span className="link" style={{ color: "#0a58ca" }}>
              <Msg id="see_more" />
            </span>
          </p>
        ),
      },
      {
        id: 1,
        date: "2024-11-06 at 01:21 PM",
        title: "card_faq1",
        hidden: true,
        content: (
          <div className="Card_Faq_Wrapper">
            <p className="mb-3">
              <strong>
                <Msg id="card_faq2" />
              </strong>
            </p>
            <p>
              <Msg id="card_faq3" />
              &nbsp;{COMPANY_EMAIL_ANCHOR}
              &nbsp;
              <Msg id="card_faq4" /> <CompanyPhoneNumber country="LT" />.
            </p>
            <p className="mt-4">
              <strong>
                <Msg id="card_faq5" />
              </strong>
            </p>
            <ol>
              <li>
                <Msg id="card_faq6" />
                &nbsp;<a href="https://wizarbit.com/login">https://wizarbit.com/login</a>
              </li>
              <li>
                <Msg id="card_faq7" />
              </li>
              <li>
                <Msg id="card_faq8" />
              </li>
              <li>
                <Msg id="card_faq9" />
              </li>
            </ol>
            <p>
              <Msg id="card_faq10" />
            </p>
            <p className="mt-4">
              <strong>
                <Msg id="card_faq11" />
              </strong>
            </p>
            <ol>
              <li>
                <Msg id="card_faq12" />
              </li>
              <li>
                <Msg id="card_faq13" />
              </li>
              <li>
                <Msg id="card_faq14" />
              </li>
            </ol>
            <p>
              <Msg id="card_faq15" />
            </p>
            <ol>
              <li>
                <Msg id="card_faq16" />
              </li>
              <li>
                <Msg id="card_faq17" />
              </li>
              <li>
                <Msg id="card_faq18" />
              </li>
              <li>
                <Msg id="card_faq19" />
              </li>
              <li>
                <Msg id="card_faq20" />
              </li>
            </ol>
            <p>
              <Msg id="card_faq21" />
            </p>
            <p>
              <Msg id="card_faq22" />{" "}
              <strong className="inline_strong">
                <Msg id="card_faq23" />
              </strong>
              <Msg id="card_faq24" />
              <br />
              <span>
                <Msg id="card_faq25" />
              </span>
            </p>
            <p className="mt-4">
              <strong>
                <Msg id="card_faq26" />
              </strong>
            </p>
            <ol>
              <li>
                <Msg id="card_faq27" />
              </li>
              <li>
                <Msg id="card_faq28" />
              </li>
              <li>
                <Msg id="card_faq28_1" />
              </li>
              <li>
                <Msg id="card_faq28_2" />
              </li>
              <li>
                <Msg id="card_faq28_3" />
              </li>
              <li>
                <Msg id="card_faq28_4" />
              </li>
              <li>
                <Msg id="card_faq28_5" />
              </li>
            </ol>

            <p className="mb-3">
              <strong>
                <Msg id="card_faq28_6" />
              </strong>
            </p>
            <p>
              <Msg id="card_faq28_7" />
            </p>
            <p>
              <Msg id="card_faq28_8" />
            </p>
            <ol>
              <li>
                <Msg id="card_faq29" />
              </li>
              <li>
                <Msg id="card_faq30" />
              </li>
              <li>
                <Msg id="card_faq31" />
              </li>
              <li>
                <Msg id="card_faq32" />
              </li>
              <li>
                <Msg id="card_faq33" />
              </li>
              <li>
                <Msg id="card_faq34" />
              </li>
              <li>
                <Msg id="card_faq35" />
              </li>
            </ol>
            <p>
              <Msg id="card_faq36" />
            </p>
            <p>
              <Msg id="card_faq37" />
            </p>
            <p className="FAQ_TABLE_WRAPPER">
              <strong>
                <Msg id="card_faq38" />
              </strong>
              <table className="FAQ_TABLE" border={1}>
                <tr>
                  <td>
                    <Msg id="card_faq40" />
                  </td>
                  <td>€ 10 000,00</td>
                </tr>
                <tr>
                  <td>
                    <Msg id="card_faq41" />
                  </td>
                  <td>€ 50 000,00</td>
                </tr>
                <tr>
                  <td>
                    <Msg id="card_faq42" />
                  </td>
                  <td>€ 600 000,00</td>
                </tr>
              </table>
            </p>
            {/* <p className="FAQ_TABLE_WRAPPER">
              <strong>
                <Msg id="card_faq43" />
              </strong>
              <table className="FAQ_TABLE">
                <tr>
                  <td>
                    <Msg id="card_faq44" />
                  </td>
                  <td>€ 5 000,00</td>
                </tr>
                <tr>
                  <td>
                    <Msg id="card_faq45" />
                  </td>
                  <td>€ 50 000,00</td>
                </tr>
                <tr>
                  <td>
                    <Msg id="card_faq46" />
                  </td>
                  <td>€ 50 000,00</td>
                </tr>
              </table>
            </p> */}
            {/* <p className="FAQ_TABLE_WRAPPER">
              <strong>
                <Msg id="card_faq47" />
              </strong>
              <table className="FAQ_TABLE">
                <tr>
                  <td>
                    <Msg id="card_faq44" />
                  </td>
                  <td>€ 10 000,00</td>
                </tr>
                <tr>
                  <td>
                    <Msg id="card_faq45" />
                  </td>
                  <td>€ 10 000,00</td>
                </tr>
                <tr>
                  <td>
                    <Msg id="card_faq46" />
                  </td>
                  <td>€ 50 000,00</td>
                </tr>
              </table>
            </p> */}
            <p className="FAQ_TABLE_WRAPPER">
              <strong>
                <Msg id="card_faq57" />
              </strong>
              <table className="FAQ_TABLE">
                <tr>
                  <td>
                    <Msg id="atm_faq1" />
                  </td>
                  <td>€ 2 000,00</td>
                </tr>
                <tr>
                  <td>
                    <Msg id="atm_faq2" />
                  </td>
                  <td>€ 10 000,00</td>
                </tr>
                <tr>
                  <td>
                    <Msg id="atm_faq3" />
                  </td>
                  <td>€ 60 000,00</td>
                </tr>
              </table>
            </p>
            <p className="mb-2 mt-4">
              <strong>
                <Msg id="card_faq48" />
              </strong>
            </p>
            <p>
              <Msg id="card_faq49" />
              <br />
              <Msg id="card_faq50" />
              &nbsp;{COMPANY_EMAIL_ANCHOR}
              <Msg id="card_faq4" />
              &nbsp;
              <CompanyPhoneNumber country="LT" />
            </p>
            <p className="my-3 mb-2">
              <strong>
                <Msg id="card_faq51" />
              </strong>
            </p>
            <p>
              <Msg id="card_faq52" />
            </p>
            <p className="my-3 mb-2">
              <strong>
                <Msg id="card_faq53" />
              </strong>
            </p>
            <p>
              <Msg id="card_faq54" />
            </p>
            <p>
              <Msg id="card_faq55" />
              <br />
              <Msg id="card_faq56" />
            </p>
          </div>
        ),
      },

      {
        id: 90,
        listTitle: "card_faq59",
        title: "card_faq58",
        date: "2025-04-01 at 14:25 PM",
        content: (
          <>
            <Msg id="card_faq59" tag="strong" />
            <p style={{ marginTop: 10 }}>
              <Msg id="card_faq60" />
            </p>
            <p style={{ marginTop: 10 }}>
              <Msg id="card_faq61" />
            </p>
            <Prices />
          </>
        ),
      },
      {
        id: 91,
        listTitle: "card_faq62",
        title: "card_faq62",
        date: "2024-08-26 at 11:43 PM",
        content: (
          <>
            <Msg id="card_faq63" tag="p" />
            <ul>
              <li>
                <Msg id="card_faq64" />{" "}
                <a href="https://t.me/wizarbit_topupbot" target="_blank" rel="noreferrer">
                  @wizarbit_topupbot
                </a>
              </li>
              <Msg id="card_faq65" tag="li" />
              <Msg id="card_faq66" tag="li" />
              <Msg id="card_faq67" tag="li" />
              <Msg id="card_faq68" tag="li" />
            </ul>
          </>
        ),
      },
      {
        id: 92,
        listTitle: "card_faq69",
        title: "card_faq69",
        date: "2024-09-06 at 11:43 PM",
        content: (
          <>
            <Msg id="card_faq70" tag="p" />
            <b>
              <Msg id="card_faq71" />
            </b>
            <ul>
              <li>
                <Msg id="card_faq72" />{" "}
                <a href="https://t.me/wizarbit_topupbot" target="_blank" rel="noreferrer">
                  @wizarbit_topupbot
                </a>
                <br />
                <Msg id="card_faq73" />
              </li>
              <li>
                <Msg id="card_faq74" />
                <br />
                <Msg id="card_faq75" />
              </li>
              <li>
                <Msg id="card_faq76" />
                <br />
                <Msg id="card_faq77" />
              </li>
              <Msg id="card_faq78" tag="li" />
              <li>
                <Msg id="card_faq79" />
                <br />
                <Msg id="card_faq80" />
              </li>
              <li>
                <Msg id="card_faq81" />
                <br />
                <Msg id="card_faq82" />
              </li>
              <Msg id="card_faq83" tag="li" />
              <li>
                <Msg id="card_faq84" />
                <br />
                <Msg id="card_faq85" />
              </li>
              <li>
                <Msg id="card_faq86" />
                <br />
                <Msg id="card_faq87" />
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  {
    id: 7,
    title: "faq115",
    prefix: "payouts",
    countOnPaper: 8,
    list: [
      {
        id: 1,
        date: "2025-03-25 at 09:43 PM",
        title: "faq116",
        content: (
          <div className="Card_Faq_Wrapper">
            <p className="mb-3">
              <strong>
                <Msg id="faq1161-1" />
              </strong>
            </p>
            <p>
              <Msg
                id="faq1161-2"
                vars={{
                  a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
                }}
              />
            </p>

            <p className="mb-3">
              <strong>
                <Msg id="faq1162-1" />
              </strong>
            </p>
            <p>
              <Msg
                id="faq1162-2"
                vars={{
                  a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
                }}
              />
            </p>

            <p className="mb-3">
              <strong>
                <Msg id="faq1163-1" />
              </strong>
            </p>
            <p>
              <Msg
                id="faq1163-2"
                vars={{
                  a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
                }}
              />
            </p>

            <p className="mb-3">
              <strong>
                <Msg id="faq1164-1" />
              </strong>
            </p>
            <p>
              <Msg
                id="faq1164-2"
                vars={{
                  a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
                }}
              />
            </p>

            <p className="mb-0">
              <strong>
                <Msg id="faq1165-1" />
              </strong>
            </p>
          </div>
        ),
      },
      {
        id: 2,
        date: "2025-03-25 at 09:47 PM",
        title: "faq118",
        content: (
          <p className="mb-0">
            <Msg
              id="faq119"
              vars={{
                a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
              }}
            />
          </p>
        ),
      },
      {
        id: 3,
        date: "2025-03-25 at 09:47 PM",
        title: "faq120",
        content: (
          <p className="mb-0">
            <Msg
              id="faq121"
              vars={{
                a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
              }}
            />
          </p>
        ),
      },
      {
        id: 4,
        date: "2025-03-25 at 09:50 PM",
        title: "faq122",
        content: (
          <p className="mb-0">
            <Msg
              id="faq123"
              vars={{
                a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
              }}
            />
          </p>
        ),
      },
      {
        id: 5,
        date: "2025-03-25 at 09:50 PM",
        title: "faq124",
        content: (
          <p className="mb-0">
            <Msg
              id="faq125"
              vars={{
                a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
              }}
            />
          </p>
        ),
      },
      {
        id: 6,
        date: "2025-03-25 at 09:50 PM",
        title: "faq126",
        content: (
          <p className="mb-0">
            <Msg
              id="faq127"
              vars={{
                a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
              }}
            />
          </p>
        ),
      },
      {
        id: 7,
        date: "2025-03-25 at 09:50 PM",
        title: "faq128",
        content: (
          <p className="mb-0">
            <Msg
              id="faq129"
              vars={{
                a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
              }}
            />
          </p>
        ),
      },
      {
        id: 8,
        date: "2025-03-25 at 09:50 PM",
        title: "faq130",
        content: (
          <p className="mb-0">
            <Msg
              id="faq131"
              vars={{
                a: (chunk: string) => <a href="/legal/aml-policy">{chunk}</a>,
              }}
            />
          </p>
        ),
      },
    ],
  },
]
