import { VALID_EMAIL } from "@usher/pe-client-front-end-lib"
import classnames from "classnames"
import { InputHTMLAttributes } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  refProps?: object
  required?: boolean
  theme?: string
  label?: string
  placeholder?: string
  containerClassName?: string
  emptyLabel?: boolean
  withSelect?: boolean
  isPinField?: boolean
}

export const CtrlInput = ({
  type = "text",
  name,
  theme = "Dark",
  label = "",
  placeholder = "",
  containerClassName,
  emptyLabel = false,
  withSelect = false,
  refProps = {},
  required = false,
  children,
  className,
  isPinField = false,
  ...rest
}: InputProps) => {
  const intl = useIntl()
  const { register, errors } = useFormContext()

  if (required) refProps = { ...refProps, required: intl.formatMessage({ id: "required_field" }) }
  if (type === "email") refProps = { ...refProps, ...VALID_EMAIL }

  // if (errors[name]) className += ' is-invalid'
  const inputProps = register({
    ...refProps,
    ...(isPinField
      ? {
          validate: (value: string) => {
            if (/(1234|2345|3456|4567|5678|6789|9876|8765|7654|6543|5432|4321)/.test(value)) {
              return "PIN must not contain consecutive digits (e.g. 1234, 3456 are invalid)."
            }

            if (/(\d)\1{3}/.test(value))
              return "Your PIN code contains identical digits. For better security, use a more varied PIN."
            //@ts-ignore
            if (refProps.validate != null) return refProps?.validate?.(value)
          },
        }
      : {}),
  })

  return (
    <div
      className={classnames(
        "Form-InputBox",
        "Order-Card-PIN-Input",
        `Form-InputBox--${theme}`,
        containerClassName,
      )}
    >
      {label && placeholder.length < 2 && (
        <span
          className="Form-Input__Label"
          style={{
            fontSize: "12px",
            lineHeight: "13px",
            fontFamily: "ChakraPetch-Regular",
          }}
        >
          {label}
        </span>
      )}
      {/* {emptyLabel && <span className="Form-Input__Label">&nbsp;</span>} */}
      <input
        className={classnames(className, "Form-Input", {
          [`Form-Input--${theme}`]: theme,
          [`Form-Input--WithSelect`]: withSelect,
          [`error`]: errors[name],
        })}
        placeholder={placeholder}
        type={type}
        name={name}
        ref={inputProps}
        maxLength={isPinField ? 4 : undefined}
        onInput={(e) => {
          if (isPinField) {
            e.preventDefault()
            e.currentTarget.value = e.currentTarget.value.replace(/\D/g, "")
          }
        }}
        {...rest}
      />
      {children}
      {errors[name] && <div className="invalid-feedback">{errors[name].message}</div>}
    </div>
  )
}
