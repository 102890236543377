import { Button } from "@/Components/Control/Button"
import "./style.sass"
import { components, getApiDataPromise, useHeaderAuth } from "@usher/pe-client-front-end-lib"
import { gtag } from "@/Helpers/gTag"
import { useHistory } from "react-router-dom"
import { useIntl } from "react-intl"
import { useMediaQuery } from "@/hooks"
import { useCallback, useEffect, useState } from "react"
import { BuyBlock } from "./BuyBlock"
import { SellBlock } from "./SellBlock"
import { IOption } from "@/types"
import { toNotNaNNumberOr } from "@/Helpers/utils"

const DEFAULT_FIAT_AMOUNT = process.env.REACT_APP_DEFAULT_FIAT_AMOUNT || "200"

export type CardType = "1" | "2"
export type CurrencyDto = { rate: string; code: string; name: string }
type CurrenciesResponseDto = {
  crypto: Record<string, CurrencyDto>
  fiat: Record<string, CurrencyDto>
}
export type CurrencyType = IOption<string, CurrencyDto>

type Props = {
  isLight?: boolean
  isBuyType: boolean
}

export const HeroBlock = ({ isLight, isBuyType }: Props) => {
  const intl = useIntl()
  const history = useHistory()
  const isMobile = useMediaQuery("(max-width: 768px)")
  const [cardType, setCardType] = useState<CardType>("1")
  const { isAuthorized } = useHeaderAuth()

  const [cryptoAmount, setCryptoAmount] = useState("")
  const [fiatAmount, setFiatAmount] = useState(DEFAULT_FIAT_AMOUNT)
  const [cryptoOptions, setCryptoOptions] = useState<CurrencyType[]>([])
  const [fiatOptions, setFiatOptions] = useState<CurrencyType[]>([])
  const [fiatCurrency, setFiatCurrency] = useState<CurrencyDto | null>(null)
  const [cryptoCurrency, setCryptoCurrency] = useState<CurrencyDto | null>(null)

  const handleChangeFiatAmount = useCallback(
    (amount: string) => {
      setFiatAmount(amount)

      const amountValue = toNotNaNNumberOr(amount, 0)
      const fiatRateValue = toNotNaNNumberOr(fiatCurrency?.rate, 1)
      const cryptoRateValue = toNotNaNNumberOr(cryptoCurrency?.rate, 1)

      setCryptoAmount((amountValue / fiatRateValue / cryptoRateValue).toFixed(6))
    },
    [cryptoCurrency?.rate, fiatCurrency?.rate]
  )

  const handleChangeCryptoAmount = useCallback(
    (amount: string) => {
      setCryptoAmount(amount)

      const amountValue = toNotNaNNumberOr(amount, 0)
      const fiatRateValue = toNotNaNNumberOr(fiatCurrency?.rate, 1)
      const cryptoRateValue = toNotNaNNumberOr(cryptoCurrency?.rate, 1)

      setFiatAmount((amountValue * fiatRateValue * cryptoRateValue).toFixed(6))
    },
    [cryptoCurrency?.rate, fiatCurrency?.rate]
  )

  useEffect(() => {
    if (cryptoAmount === "" && fiatCurrency?.rate != null && cryptoCurrency?.rate != null) {
      handleChangeFiatAmount(DEFAULT_FIAT_AMOUNT)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cryptoCurrency?.rate, fiatCurrency?.rate, handleChangeFiatAmount])

  const handleFetchCurrencies = useCallback(() => {
    getApiDataPromise<CurrenciesResponseDto>({}, "exchange", "get_currencies").then((res) => {
      const cryptoOptionsList = Object.entries(res.data.crypto).map(([value, item]) => ({
        value,
        label: value,
        payload: item,
      }))

      const fiatOptionsList = Object.entries(res.data.fiat).map(([value, item]) => ({
        value,
        label: value,
        payload: item,
      }))

      setCryptoOptions(cryptoOptionsList)
      setFiatOptions(fiatOptionsList)

      setCryptoCurrency(cryptoOptionsList?.[0]?.payload ?? null)
      setFiatCurrency(fiatOptionsList?.[0]?.payload ?? null)
    })
  }, [])

  const handleBuyClick = useCallback(() => {
    gtag("event", "click_exchange", {
      input_amount: fiatAmount,
      input_currency: fiatCurrency.code,
      output_amount: cryptoAmount,
      output_currency: cryptoCurrency.code,
    })()

    history.push("/exchange")
  }, [cryptoAmount, cryptoCurrency?.code, fiatAmount, fiatCurrency?.code, history])

  const handleSellClick = useCallback(() => {
    if (isAuthorized) {
      const query = [
        `card_type=${cardType}`,
        fiatCurrency?.code != null ? `fiat_currency=${fiatCurrency?.code}` : null,
        `fiat_amount=${fiatAmount}`,
      ].join("&")

      history.push(`/client/topup/by-crypto?${query}`)
    } else {
      history.push("/login")
    }
  }, [cardType, fiatAmount, fiatCurrency?.code, history, isAuthorized])

  useEffect(() => {
    handleFetchCurrencies()
  }, [handleFetchCurrencies])

  return (
    <div className="hero-block d-flex flex-column align-items-center">
      <div className="d-flex flex-column gap-3">
        {isBuyType ? (
          <BuyBlock
            cryptoAmount={cryptoAmount}
            cryptoCurrency={cryptoCurrency}
            cryptoOptions={cryptoOptions}
            isMobile={isMobile}
            onCryptoAmountChange={handleChangeCryptoAmount}
            onCryptoCurrencyChange={setCryptoCurrency}
            isLight={isLight}
            fiatAmount={fiatAmount}
            fiatCurrency={fiatCurrency}
            fiatOptions={fiatOptions}
            onFiatAmountChange={handleChangeFiatAmount}
            onFiatCurrencyChange={setFiatCurrency}
            onBuyClick={handleBuyClick}
          />
        ) : (
          <SellBlock
            cardType={cardType}
            onCardTypeChange={setCardType}
            cryptoAmount={cryptoAmount}
            cryptoCurrency={cryptoCurrency}
            cryptoOptions={cryptoOptions}
            isMobile={isMobile}
            fiatAmount={fiatAmount}
            onFiatAmountChange={handleChangeFiatAmount}
            onCryptoAmountChange={handleChangeCryptoAmount}
            onCryptoCurrencyChange={setCryptoCurrency}
            isLight={isLight}
            fiatCurrency={fiatCurrency}
            fiatOptions={fiatOptions}
            onFiatCurrencyChange={setFiatCurrency}
            onSellClick={handleSellClick}
          />
        )}

        <div className="exchange__block-info">
          <components.Timer
            onUpdate={handleFetchCurrencies}
            wrapWithTag=""
            secText="s"
            text={intl.formatMessage({ id: "recalculate" })}
          />
        </div>
      </div>

      {isMobile ? (
        <Button
          style={{ marginTop: "32px" }}
          onClick={isBuyType ? handleBuyClick : handleSellClick}
        >
          {intl.$t({ id: isBuyType ? "buy_button_title" : "sell_button_title" })}{" "}
          {cryptoCurrency?.name ?? ""}
        </Button>
      ) : null}
    </div>
  )
}
