import { IOption } from "@/types"
import classNames from "classnames"
import { CSSProperties, ReactNode, RefObject, useEffect, useRef, useState } from "react"
import "./style.css"

export interface IBSelectProps<T extends string> {
  label?: string
  placeholder?: string
  errorText?: string
  value?: IOption<T> | null
  options: IOption<T>[]
  isDark?: boolean
  fullWidth?: boolean
  onSelect?: (value: IOption | null) => void
  size?: "small" | "large"
  getOptionLabel?: (value: IOption<T>) => T
  leftIcon?: ReactNode
  renderOption?: (option: IOption<T>, index: number) => ReactNode
  searchable?: boolean
  buttonStyle?: CSSProperties
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
  menuStyle?: CSSProperties
  noOptionText?: string
  centered?: boolean
  isError?: boolean
  renderLabel?: (option: IOption<T>) => ReactNode
  listRef?: RefObject<HTMLUListElement>
  onOpen?: () => void
  onClose?: () => void
  onLoadBtnRef?: (btnRef: RefObject<HTMLButtonElement>) => void
}

const DropdownArrow = () => {
  return (
    <svg
      className="dropdown-icon"
      width="7"
      height="8"
      viewBox="0 0 7 8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.34938 3.70133L5.49909 1.3055C5.13123 2.82969 5.10248 4.41321 5.41065 5.94398C4.23904 4.91172 2.8533 4.14485 1.34938 3.70133Z" />
    </svg>
  )
}
export const BSelect = <T extends string>({
  isDark,
  value,
  searchable,
  buttonStyle,
  menuStyle,
  size,
  noOptionText,
  inputProps,
  label,
  renderLabel,
  onOpen,
  onClose,
  listRef,
  isError = false,
  onLoadBtnRef,
  ...props
}: IBSelectProps<T>) => {
  const dropdown = useRef<HTMLDivElement>(null)
  const menu = useRef<HTMLDivElement>(null)
  const input = useRef<HTMLInputElement>(null)
  const btnRef = useRef<HTMLButtonElement>(null)
  const [selected, setSelected] = useState<IOption<T> | null>(null)
  const { options, onSelect, renderOption, getOptionLabel, centered = false, ...rest } = props
  const [opts, setOpts] = useState<IOption[]>(options)

  useEffect(() => {
    setOpts(options)
  }, [options])

  useEffect(() => {
    setSelected(value)
    if (input.current != null) input.current.value = value?.label ?? selected?.label ?? ""
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, input])

  const onShowDropdown = (e: Event) => {
    onOpen?.()
  }
  const onCloseDropdown = (e: Event) => {
    onClose?.()
  }
  useEffect(() => {
    const current = dropdown.current
    if (current != null) {
      current.addEventListener("shown.bs.dropdown", onShowDropdown)
      current.addEventListener("hide.bs.dropdown", onCloseDropdown)
    }
    return () => {
      current?.removeEventListener("shown.bs.dropdown", onShowDropdown)

      current.removeEventListener("hide.bs.dropdown", onCloseDropdown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown])

  useEffect(() => {
    if (btnRef.current != null) onLoadBtnRef?.(btnRef)
  }, [btnRef, onLoadBtnRef])
  return (
    <div style={{ position: "relative", width: rest.fullWidth ? "100%" : "auto" }}>
      {label != null && (
        <span className={`${props.errorText != null ? "bs-error-label" : ""}`}>{label}</span>
      )}
      <div
        ref={dropdown}
        className={classNames("dropdown b-select-wrapper", {
          centered: centered,
          dropdownLight: !isDark,
        })}
      >
        <button
          ref={btnRef}
          className={classNames("b-select", {
            BSelectLarge: size === "large",
            error_input: isError,
          })}
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          style={buttonStyle}
        >
          {props.leftIcon}
          {searchable ? (
            <input
              onBlur={(e) => {
                if (document.activeElement === e.target) e.target.focus()
              }}
              ref={input}
              onInput={(value) => {
                if (!menu.current.classList.contains("show")) btnRef.current.click()
                const val = value.currentTarget.value
                const buffer = options.filter((option) =>
                  option.label.toLowerCase().includes(val.toLowerCase()),
                )
                setOpts(buffer)
              }}
              {...inputProps}
            />
          ) : (
            <>
              {renderLabel?.(selected) ?? getOptionLabel?.(selected) ?? (
                <span>{selected?.label}</span>
              )}
            </>
          )}
          <DropdownArrow />
        </button>
        <div className="connector"></div>
        <div
          style={menuStyle}
          ref={menu}
          className="dropdown-menu b-select-menu"
          aria-labelledby="dropdownMenuButton1"
        >
          {opts.length === 0 && <span className="no-option">{noOptionText ?? "No options"}</span>}
          <ul ref={listRef}>
            {opts.map((option, ind) => (
              <li
                key={ind}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (onSelect != null) onSelect(option)
                  setSelected(option as IOption<T>)
                  if (input.current != null) input.current.value = option.label
                }}
              >
                <div className="dropdown-item">
                  {renderOption != null ? (
                    renderOption(option as IOption<T>, ind)
                  ) : (
                    <span>{option.label}</span>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {rest.errorText && <span className="bs-error-label">{rest.errorText}</span>}
    </div>
  )
}
