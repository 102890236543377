import React, { memo, useState } from "react"
import type { FC } from "react"
import { Triangle } from "./Triangle"
import { FiatItem } from "./FiatItem"
import { CardType, CurrencyDto, CurrencyType } from "./HeroBlock"
import { useIntl } from "react-intl"
import clsx from "clsx"

const CardItem = ({
  title,
  description,
  isActive,
  onClick,
}: {
  title: string
  description: string
  onClick: () => void
  isActive: boolean
}) => (
  <div className={clsx("card-item", { active: isActive })} onClick={onClick}>
    <div className="icon">
      <img src={`/img/icons_new/card_icon.svg`} alt="" />
    </div>

    <div className="info">
      <span className="title">{title}</span>
      <span className="description">{description}</span>
    </div>
  </div>
)

const CurrencyButton = ({
  isOpen,
  onClick,
  title,
}: {
  isOpen: boolean
  title: string
  onClick: () => void
}) => (
  <div className="currency-button" onClick={onClick}>
    <span className="title">{title}</span>
    <Triangle isOpen={isOpen} />
  </div>
)

type Props = {
  currency: CurrencyDto | null | undefined
  currencies: CurrencyType[]
  cardType: CardType
  onCardTypeChange: (cardType: CardType) => void
  onFiatCurrencyChange: (currency: CurrencyDto) => void
}

const CardsItemComponent: FC<Props> = ({
  currencies,
  currency,
  onFiatCurrencyChange,
  cardType,
  onCardTypeChange,
}) => {
  const { $t: t } = useIntl()

  const [isOpen, setIsOpen] = useState(false)

  console.log(currency)

  return (
    <div className="cards-item">
      <CurrencyButton
        isOpen={isOpen}
        title={currency?.code ?? ""}
        onClick={() => setIsOpen((x) => !x)}
      />

      <span className="title">{t({ id: "card_type_title" })}</span>

      <div className="cards-container">
        <CardItem
          isActive={cardType === "1"}
          title={t({ id: "card_type_title_1" })}
          description={t({ id: "card_type_description_1" })}
          onClick={() => onCardTypeChange("1")}
        />
        <CardItem
          isActive={cardType === "2"}
          title={t({ id: "card_type_title_2" })}
          description={t({ id: "card_type_description_2" })}
          onClick={() => onCardTypeChange("2")}
        />
      </div>

      {isOpen ? (
        <div className="currencies-list">
          {currencies.map((x) => (
            <FiatItem
              key={x.value}
              isActive={x.value === currency.code}
              className="cards-currency"
              currency={x.value}
              name={x.payload.name}
              onClick={() => {
                setIsOpen(false)
                onFiatCurrencyChange(x.payload)
              }}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}

export const CardsItem = memo(CardsItemComponent)
