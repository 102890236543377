import { Button } from "@/Components/Control/Button"
import { useSearch } from "@/hooks/useSearch"
import { memo, useCallback } from "react"
import type { ButtonHTMLAttributes, FC } from "react"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"

const TabsComponent: FC = () => {
  const { $t: t } = useIntl()
  const history = useHistory()

  const { query } = useSearch<{ type: "buy" | "sell" }>({ defaultValues: { type: "buy" } })

  const handleClick = useCallback<NonNullable<ButtonHTMLAttributes<HTMLButtonElement>["onClick"]>>(
    (event) => {
      if (event.target instanceof HTMLButtonElement) {
        history.replace({ search: `type=${event.target.dataset.value ?? "buy"}` })
      }
    },
    [history]
  )

  return (
    <div className="d-flex justify-content-center gap-2">
      <Button
        onClick={handleClick}
        data-value="buy"
        variant={query.type === "buy" ? "white" : "outlined-black"}
      >
        {t({ id: "buy_button_title" })}
      </Button>
      <Button
        onClick={handleClick}
        data-value="sell"
        variant={query.type === "sell" ? "white" : "outlined-black"}
      >
        {t({ id: "sell_button_title" })}
      </Button>
    </div>
  )
}

export const Tabs = memo(TabsComponent)
