import AboutEn from "@/Languages/about/en.json"
import AboutLt from "@/Languages/about/lt.json"
import BlogEn from "@/Languages/blog/en.json"
import BlogLt from "@/Languages/blog/lt.json"
import CardFaqEn from "@/Languages/card-faq/en.json"
import CardFaqLt from "@/Languages/card-faq/lt.json"
import CardFramework from "@/Languages/card-framework.en.json"
import CardFramewordLt from "@/Languages/card-framework.lt.json"
import CardTerms from "@/Languages/card-terms.en.json"
import CardTermsLt from "@/Languages/card-terms.lt.json"
import English from "@/Languages/en.json"
import Litva from "@/Languages/lt.json"
import WizarbitTermsEn from "@/Languages/wizarbit-terms/en.json"
import WizarbitTermsLt from "@/Languages/wizarbit-terms/lt.json"
export const LANGUAGES = [
  {
    code: "en-US",
    label: "English",
    messages: {
      ...English,
      ...CardFramework,
      ...CardTerms,
      ...AboutEn,
      ...BlogEn,
      ...CardFaqEn,
      ...WizarbitTermsEn,
    },
  },
  {
    code: "lt-LT",
    label: "Lietuviškai",
    messages: {
      ...Litva,
      ...CardFramewordLt,
      ...CardTermsLt,
      ...AboutLt,
      ...BlogLt,
      ...CardFaqLt,
      ...WizarbitTermsLt,
    },
  },
]
