import { Msg } from "@/Components/Msg"

export const Header = () => {
  return (
    <div className="blog-header">
      <div className="blog-header__chip">
        <Msg id="news" />
      </div>
      <div className="blog-header__title">
        <Msg id="blog_title" />
      </div>
    </div>
  )
}
